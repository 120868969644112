<template>
  <div>
    <div class="listInfo">
      <div class="title hiddenMobile">시즌권 입장내역</div>
      <div class="sort">
        <div class="filter">
          <!-- filter -->
          <div class="filterItem shortcut">
            <button
                v-for="s in shortcuts"
                :key="s.id"
                type="button"
                @click="setTerm(s.id)"
                :class="{current: s.id === shortcut}">
              <span>{{s.name}}</span>
            </button>
          </div>
          <!-- //filter -->
          <!-- datepicker -->
          <div class="filterItem datePicker hiddenMobile">
            <date-picker v-model="term.from" valueType="format"></date-picker> ~
            <date-picker v-model="term.to" valueType="format"></date-picker>
            <button type="button" class="btn whiteLightgrey2 auto btnSearch" @click="search">검색</button>
          </div>
          <!-- //datepicker -->
        </div>
      </div>
    </div>
    <div class="dataTable">
      <div v-if="showData">
        <table>
          <thead>
          <tr>
            <th>예매번호</th>
            <th>예매경기</th>
            <th>경기일자</th>
            <th>입장시간</th>
            <th>입장여부</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(el, i) in seasonList" :key="i">
            <td>
              <div class="bold pdb10">{{el.season}}시즌권 {{el.ticket_place}}</div>
              <div class="league">{{el.number}}</div>
              </td>
            <td class="left">
              <div class="pdl20">
              <div class="league pdb10" v-if="el.title!==''">{{el.title}}</div>
              <div class="bold">{{el.matchInfo}}</div>
              </div>
            </td>
            <td class="date">{{el.setGameDate}}({{el.yoil}})</td>
            <td class="qty">{{el.setGameDate}}({{el.yoil}}) {{el.setGameTime}}</td> <!-- 디자인상 모바일용 데이터로 번호 한번 더 넣어야함 -->
            <td class="status">{{el.entrance_state}}</td>
          </tr>
          </tbody>
        </table>
        <Pagination
            :perPage="6"
            :pages="pages"
            @change="getData"
            v-show="showData"
        />
      </div>
      <div class="noData" v-else>
        시즌권 입장내역이 없습니다
<!--        <div><a href="#" class="btn primary2 w100">시즌권 구매</a></div>-->
      </div>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Pagination from '@/components/Util/Pagination'
import http from "@/api/mypageApi"
import regExp from "@/common/regExp"

export default {
  name: 'MypagePayEntrySeason',
  components: {
    DatePicker,
    Pagination
  },
  computed: {
    showData () {
      return Object.keys(this.seasonList).length
    }
  },
  created() {
    const termFrom = dayjs().startOf('year').format()
    const termTo = dayjs().format()
    this.term = {
      from: termFrom,
      to: termTo
    }
    let page = this.$route.params.page || '1';
    this.getData(page)
  },
  data () {
    return {
      state: true,
      shortcut: 'thisYear',
      shortcuts: [
        { id: 'thisMonth', name: '이번달' },
        { id: 'lastMonth', name: '지난달' },
        { id: 'thisYear', name: '올해' },
        { id: 'lastYear', name: '지난해' }
      ],
      term: {
        from: '',
        to: ''
      },
      seasonList: [],
    }
  },
  methods: {
    getData (page, type) {
      let range = `${dayjs(this.term.from).format('YYYY-MM-DD')},${dayjs(this.term.to).format('YYYY-MM-DD')}`
      let query = `?page=${page}&range=${range}&limit=6`
      http.get(`/seasonpass/entrance${query}`).then(res => {
        let data = res.data.data;
        data.list.map(el => {
          el.setGameDate = dayjs(el.game_date).format('YYYY.MM.DD')
          el.setGameTime = el.game_time.substring(0, 5)
          el.yoil = dayjs(el.game_date).locale('ko').format('ddd')
          el.entrance_state_code === 'ENTERED' ? el.entrance_state = '입장' : el.entrance_state = '미입장'
          const parts = el.match_info.split(']')
          if (parts.length > 1) {
            el.title = `[${parts[0].substring(1)}]`
            el.matchInfo = parts[1].trim()
          } else {
            el.title = ''
            el.matchInfo = el.match_info
          }
          })
        this.seasonList = data.list
        this.pages = data.total
      }).catch(err => {
        console.log(err);
      })

    },
    setTerm (shortcut) {
      const term = this.term
      switch (shortcut) {
        case 'thisMonth':
          term.from = dayjs().startOf('month').format()
          term.to = dayjs().format()
          break
        case 'lastMonth':
          term.from = dayjs().add(-1, 'month').startOf('month').format()
          term.to = dayjs().add(-1, 'month').endOf('month').format()
          break
        case 'thisYear':
          term.from = dayjs().startOf('year').format()
          term.to = dayjs().format()
          break
        case 'lastYear':
          term.from = dayjs().add(-1, 'year').startOf('year').format()
          term.to = dayjs().add(-1, 'year').endOf('year').format()
          break
        case 'hidden':
          term.from = ''
          term.to = ''
          break
      }
      this.shortcut = shortcut
      if (this.shortcut === 'hidden') {
        this.hidden = true
        this.getData(1,'hidden')
      } else {
        this.hidden = false
        this.getData(1)
      }
    },
    search () {
      if(!this.term.from || !this.term.to) {
        alert('날짜를 확인해주세요');
        return false;
      }
      this.getData(1);
    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    }
  }
}
</script>

<style lang="scss" scoped>
.no2 {
  display: none;
}
.pdb10 {
  padding-bottom: 10px;
}
.pdl20 {
  padding-left: 20px;
}

.left {
  text-align: inherit;
}
@media screen and (max-width: 767px) {
  .pdl20 {
    padding-left: 0;
  }
  .pdb10 {
    padding-bottom: 0;
  }
  tbody {
    .no {
      display: none;
    }
    .league {
    }
    .date {
      color: $grey3;
    }
    .qty,
    .status {
      display: inline-block;
      vertical-align: middle;
    }
    .no2 {
      display: inline-block;
      vertical-align: middle;
    }
    .no2:after,
    .status:before {
      content: "|";
      margin: 0 8px;
      color: $lightgrey1;
    }
  }
}
</style>

