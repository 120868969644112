<template>
  <div>
    <div class="topTitle">
      <div class="title">전북현대<br>회원가입 회원정보 입력</div>
      <div class="subTitle">전북현대모터스에프씨 웹사이트 가입을 위해서 아래 내용을 기입하세요.<br>필수항목은 모두 기입하셔야 가입이 가능합니다.</div>
    </div>
    <!-- 아이디 -->
    <div class="formGroup">
      <label class="label">아이디 <span class="required">(필수)</span></label>
      <!-- 앱회원 -->
      <input v-if="isAppMember" type="text" class="input" disabled v-model="info.id.value">
      <!-- //앱회원 -->
      <!-- 비회원 -->
      <div v-else class="withBtn">
        <input type="text" class="input" placeholder="아이디를 입력하세요" ref="idInput" v-model="info.id.value">
        <button type="button" class="btn whiteLightgrey" @click="checkDuplicateId">아이디 중복체크</button>
        <p v-if="info.id.pass" class="confirmMessage">사용 가능한 아이디입니다</p>
      </div>
      <!-- //비회원 -->
    </div>
    <!-- //아이디 -->
    <!-- 비밀번호 -->
    <div class="formGroup">
      <label class="label">비밀번호 입력 <span class="required">(필수)</span></label>
      <input type="password" class="input" placeholder="비밀번호를 입력하세요" v-model="info.pw1.value" @keyup="matchPassword">
      <p class="inputMessage"><span v-if="info.pw1.pass" class="confirmMessage"></span> 영어 대/소문자, 특수문자, 숫자를 조합한 9~20자리로 입력하세요</p>
    </div>
    <!-- //비밀번호 -->
    <!-- 비밀번호 확인 -->
    <div class="formGroup">
      <label class="label">비밀번호 확인 <span class="required">(필수)</span></label>
      <input type="password" class="input" placeholder="비밀번호를 다시 입력하세요" v-model="info.pw2.value" @keyup="matchPassword">
      <p v-if="info.pw2.pass" class="confirmMessage">비밀번호가 일치합니다</p>
    </div>
    <!-- //비밀번호 확인 -->
    <!-- 이름 -->
    <div class="formGroup">
      <label class="label">이름 <span class="required">(필수)</span></label>
      <input type="text" class="input" disabled v-model="info.name.value">
    </div>
    <!-- //이름 -->
    <!-- 성별 -->
<!--    <div class="formGroup">-->
<!--      <label class="label">성별 <span class="required">(필수)</span></label>-->
<!--      <input type="text" class="input" disabled v-model="info.sex.value">-->
<!--    </div>-->
    <!-- //성별 -->
    <!-- 이메일 -->
    <div class="formGroup" v-if="isChild">
      <label class="label">보호자 동의</label>
      <div class="withBtn">
        <input type="email" class="input" :placeholder=" chkChildAuth ? '보호자 인증 완료' : '동의 이전'" disabled>
        <button type="button" class="btn whiteLightgrey" @click="cert" :disabled="chkChildAuth">휴대폰 인증</button>
      </div>
    </div>
    <!-- //이메일 -->
    <!-- 휴대폰번호 -->
    <div class="formGroup">
      <label class="label">휴대폰번호 <span class="required">(필수)</span></label>
      <!-- 휴대폰인증 -->
      <input v-if="certType === 'phone'" type="text" class="input" disabled v-model="info.phone.value">
      <!-- //휴대폰인증 -->
      <!-- 아이핀인증 -->
      <div v-else-if="certType === 'ipin'">
        <div class="withBtn">
          <input type="tel" class="input" placeholder="휴대폰번호를 입력하세요" ref="phoneInput" :value="info.phone.value" @input="phoneFormatter" @keyup.enter.prevent="setPhoneCert">
          <button type="button" class="btn whiteLightgrey" @click="setPhoneCert">인증코드 요청</button>
        </div>
        <div v-if="info.phone.cert" class="withBtn formRow">
          <input type="text" class="input" placeholder="인증코드를 입력하세요" ref="phoneCertInput" v-model="phoneCert" @keyup.enter.prevent="checkPhoneCert">
          <button type="button" class="btn whiteLightgrey" @click="checkPhoneCert">인증코드 확인</button>
        </div>
        <p v-if="info.phone.pass" class="confirmMessage">인증코드가 일치합니다</p>
      </div>
      <!-- //아이핀인증 -->
    </div>
    <!-- //휴대폰번호 -->
    <!-- 이메일 -->
    <div class="formGroup">
      <label class="label">이메일 주소 <span class="required">(필수)</span></label>
      <div class="withBtn">
        <input type="email" class="input" placeholder="이메일 주소를 입력하세요" ref="emailInput" v-model="info.email.value" @keyup.enter.prevent="checkDuplicateEmail">
        <button type="button" class="btn whiteLightgrey" @click="checkDuplicateEmail">이메일 중복체크</button>
        <p v-if="info.email.pass" class="confirmMessage">사용 가능한 이메일입니다</p>
      </div>
    </div>
    <!-- //이메일 -->
    <!-- 광고성 수신동의 -->
    <div class="formGroup">
      <label class="label">광고성 수신동의 <span class="required">(선택)</span></label>
      <div class="textarea height110" v-html="terms">광고성 수신동의 내용</div>
      <div class="checkAgree">
        <div class="checkbox radio">
          <input
            type="radio"
            name="agree_sms"
            id="agree_sms_y"
            value="Y"
            v-model="info.sms.value">
          <label for="agree_sms_y">동의</label>
        </div>
        <div class="checkbox radio">
          <input
            type="radio"
            name="agree_sms"
            id="agree_sms_n"
            value="N"
            v-model="info.sms.value">
          <label for="agree_sms_n">미동의</label>
        </div>
      </div>
    </div>
    <!-- //광고성 수신동의 -->
    <!-- 야간수신동의 -->
    <div class="formGroup" v-if="info.sms.value === 'Y'">
      <label class="label">야간 광고성 수신동의<span class="required">(선택)</span></label>
      <div class="textarea height110" v-html="nightPolicy"></div>
      <div class="checkAgree">
        <div class="checkbox radio">
          <input
              type="radio"
              name="agree_nightAdSms"
              id="agree_nightAdSms_y"
              v-model="info.nightAdSms.value"
              value="Y">
          <label for="agree_nightAdSms_y">동의</label>
        </div>
        <div class="checkbox radio">
          <input
              type="radio"
              name="agree_nightAdSms"
              id="agree_nightAdSms_n"
              v-model="info.nightAdSms.value"
              value="N">
          <label for="agree_nightAdSms_n">미동의</label>
        </div>
      </div>
    </div>
    <!--//야간수신동의 -->
    <!-- 우편번호 -->
    <div class="formGroup">
      <label class="label">우편번호 검색 <span class="required">(필수)</span></label>
      <div class="withBtn">
        <input type="email" class="input" placeholder="우편번호를 검색하세요" disabled v-model="info.zipcode.value">
        <button type="button" class="btn whiteLightgrey" @click="findZipcode">우편번호 검색</button>
      </div>
      <div class="zipcodeWrap" ref="embed"></div>
    </div>
    <!-- //우편번호 -->
    <!-- 주소
    <div v-if="info.zipcode.value" class="formGroup">
      <label class="label">주소 <span class="required">(필수)</span></label>
      <div class="formRow">
        <input type="text" class="input" value="경기 성남시 대왕판교로 231" v-model="info.address1.value" disabled>
      </div>
      <div class="formRow">
        <input type="text" class="input" placeholder="상세주소를 입력하세요" ref="addressInput" v-model="info.address2.value">
      </div>
    </div>
    //주소 -->
    <!-- 좋아하는 선수 -->
    <div class="formGroup">
      <label class="label">좋아하는 선수 <span class="required">(필수)</span></label>
      <select class="input" v-model="info.favorite.value">
        <option value="">선택하세요</option>
        <option v-for="player in playerList" :value="player.idx">{{ player.name }}</option>
      </select>
    </div>
    <!-- //좋아하는 선수 -->
    <!-- 직업 -->
    <div class="formGroup">
      <label class="label">직업 <span class="required">(선택)</span></label>
      <select class="input" v-model="info.job.value">
        <option value="">선택하세요</option>
        <option v-for="job in jobList" :value="job">{{ job }}</option>
      </select>
    </div>
    <!-- //직업 -->
    <!-- 결혼여부 -->
    <div class="formGroup">
      <label class="label">결혼여부 <span class="required">(선택)</span></label>
      <select class="input" v-model="info.marry.value">
        <option value="">선택하세요</option>
        <option value="y">기혼</option>
        <option value="n">미혼</option>
      </select>
    </div>
    <!-- //결혼여부 -->
    <!-- 현대차 임직원 여부 -->
    <div class="formGroup employee">
      <label class="label">전주공장 사원번호 <span class="required">(선택)</span></label>
      <div class="checkAgree">
        <div class="checkbox radio">
          <input
              type="radio"
              name="employee"
              id="employee_y"
              value="Y"
              v-model="info.employee.value">
          <label for="employee_y">여</label>
        </div>
        <div class="checkbox radio">
          <input
              type="radio"
              name="employee"
              id="employee_n"
              value="N"
              v-model="info.employee.value">
          <label for="employee_n">부</label>
        </div>
      </div>
      <input type="text" class="input employeeVal" placeholder="임직원번호를 입력하세요." v-model="info.employeeNo.value" :disabled="info.employee.value === 'N'">
    </div>
    <!-- //현대차 임직원 여부 -->
    <div class="formBtns">
      <button type="button" class="btn mobileFull large primary1" @click="nextStep">회원가입</button>
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from "@/api";
import getPlayer from '@/common/getPlayer'
import lib from '@/library/common'
import terms from '@/library/terms'
import dayjs from "dayjs"

export default {
  name: 'SignupStep4',
  props: [
    'certType',
    'certInfo'
  ],
  computed: {
    validate () {
      const info = Object.keys(this.info).map(x => {
        return this.info[x]
      })
      const invalidValue = info.filter(x => x.required && this.isEmpty(x.value)).map(x => x.name)
      const inValidPass = info.filter(x => x.required && x.pass === false).map(x => passMessage(x.id))
      function passMessage (type) {
        let result = ''
        switch (type) {
          case 'id':
            result = '아이디 중복체크를 해주세요'
            break
          case 'pw1':
            result = '비밀번호를 영어 대/소문자, 특수문자, 숫자를 조합한 9~20자리로 입력하세요'
            break
          case 'pw2':
            result = '비밀번호를 확인해주세요'
            break
          case 'phone':
            result = '휴대폰 인증코드를 확인해주세요'
            break
          case 'email':
            result = '이메일 중복체크를 해주세요'
            break
        }
        return result
      }
      return {
        inValidValue: invalidValue.length === 0 ? null : invalidValue.join(', '),
        inValidPass: inValidPass,
        result: invalidValue.length === 0 && inValidPass.length === 0
      }
    }
  },
  async created() {
    // 앱회원:: 아이디 존재
    // console.log('>>>>>>>>>>>>> INIT',  this.certInfo )

    // if (this.certInfo === null) {
    //   alert( "비정상 접근입니다" );
    //   document.location.href = "/member/signup/1"
    // }

    if (this.certInfo.exist === "true" && this.certInfo.sRtnMSG !== '구 모바일 회원') {
      alert('이미 가입된 회원입니다.');
      this.$router.push('/member/signin');
    }
    let agreeReceiveAdvertising = ''
    JSON.parse(localStorage.getItem('agreeReceiveAdvertising')) === true
     ? agreeReceiveAdvertising = 'Y'
        : agreeReceiveAdvertising = 'N'
    this.info.sms.value = agreeReceiveAdvertising

    if (this.certInfo.userIdFix === "true") {
      alert('구 모바일 회원입니다.');
      this.info.id.value = this.certInfo.userId
      this.info.id.pass = true
      this.isAppMember = true
      if(this.certInfo.exist === "true"){
        this.info.phone.value = this.certInfo.mobileno
      }
    }

    // this.info.id.value = 'hongildong123'
    // this.info.id.pass = true
    // this.isAppMember = true
    // 아이핀인증 + 휴대폰인증:: 이름/성별 존재

    const age = dayjs().diff(dayjs(this.certInfo.birthdate, 'YYYYMMDD'), 'year')
    if(age < 14) { // 14세 미만인지 아닌지 확인
      this.isChild = true
    }
    this.info.name.value = this.certInfo.name

    if (this.certInfo.gender === "1") {
      this.info.sex.value = '남'
    } else if (this.certInfo.gender === "0") {
      this.info.sex.value = "여"
    }

    // 휴대폰인증:: 휴대폰번호 존재
    if (this.certType === 'phone') {
      this.info.phone.value = this.certInfo.mobileno.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);;
      this.info.phone.pass = true
    }

    this.userDi = this.certInfo.dupinfo
    this.userCi = this.certInfo.conninfo
    this.originEncodeData = this.certInfo.originEncodeData

    // console.log(getPlayer)
    this.playerList = await getPlayer.getPlayer();

  },
  data () {
    return {
      terms: terms.policy_3,
      nightPolicy: terms.policy_8,
      isAppMember: false,
      playerList: [],
      jobList: lib.jobList,
      info: {
        id: {
          id: 'id',
          name: '아이디',
          value: '',
          required: true,
          pass: false // 앱회원 아니고 중복체크 완료하면 true
        },
        pw1: {
          id: 'pw1',
          name: '비밀번호',
          value: '',
          required: true,
          pass: false // 비밀번호 패턴 통과되면 true
        },
        pw2: {
          id: 'pw2',
          name: '비밀번호 확인',
          value: '',
          required: true,
          pass: false // 비밀번호 확인되면 true
        },
        name: {
          id: 'name',
          name: '이름',
          value: '',
          required: true
        },
        sex: {
          id: 'sex',
          name: '성별',
          value: '',
          required: true
        },
        phone: {
          id: 'phone',
          name: '휴대폰번호',
          value: '',
          required: true,
          pass: false, // 아이핀 인증이고 휴대폰인증 완료하면 true
          cert: false //아이핀 인증이고 인증코드 요청한 상태이면 true
        },
        email: {
          id: 'email',
          name: '이메일 주소',
          value: '',
          required: true,
          pass: false // 이메일 중복체크 완료하면 true
        },
        sms: {
          id: 'sms',
          name: '광고성 수신동의',
          value: 'y',
          required: false
        },
        nightAdSms: {
          name: '야간 광고성 수신동의',
          value: 'y',
          required: false
        },
        zipcode: {
          id: 'zipcode',
          name: '우편번호 검색',
          value: '',
          required: true
        },
        /*
        address1: {
          id: 'address1',
          name: '주소',
          value: '',
          required: true
        },
        address2: {
          id: 'address2',
          name: '상세 주소',
          value: '',
          required: true
        },
        */
        favorite: {
          id: 'favorite',
          name: '좋아하는 선수',
          value: '',
          required: true
        },
        job: {
          id: 'job',
          name: '직업',
          value: '',
          required: false
        },
        marry: {
          id: 'marry',
          name: '결혼 여부',
          value: '',
          required: false
        },
        employee: {
          id: 'employee',
          name: '전주공장 임직원 여부',
          value: 'N',
          required: false
        },
        employeeNo: {
          id: 'employeeNo',
          name: '임직원번호',
          value: '',
          required: false
        },
      },
      userDi: '',
      userCi: '',
      originEncodeData: '',
      phoneCert: '',
      loading: false,
      isChild: false, // 가입자 14세 미만 확인
      chkChildAuth: false,// 14세 미만 가입자의 보호자 인증 확인
      nokInfo: {} // 14세 미만 가입자의 보호자 인증 정보
    }
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  methods: {
    isEmpty (str) {
      return regExp.replaceAllEmpty(`${str}`).length === 0
    },
    focusRefs (ref) {
      setTimeout(() => {
        if (this.$refs[ref]) {
          this.$refs[ref].focus()
        }
      }, 100)
    },
    checkDuplicateId () {
      if(this.loading){
        return
      }

      if (this.isEmpty(this.info.id.value)) {
        alert('아이디를 입력하세요')
        this.focusRefs('idInput')
        return this.loading = false;
      } else {
        this.loading = true;
        // 아이디 중복검사
        http.get('/user/checkId', {
          params: {
            userId: this.info.id.value
          }
        }).then(res => {
          if (res.data.success === true) {
            alert('사용 가능한 아이디입니다.')
            this.info.id.pass = true
          } else {
            alert('이미 사용중인 아이디입니다.')
            this.focusRefs('idInput')
          }
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    setPhoneCert () {
      if(this.loading) {
        return
      }
      this.loading = true;
      if (this.isEmpty(this.info.phone.value)) {
        this.focusRefs('phoneInput')
        alert('휴대폰번호를 입력하세요')
        return this.loading = false
      } else if (!regExp.expPhone.test(this.info.phone.value)) {
        alert('올바른 휴대폰번호 형식이 아닙니다')
        return this.loading = false
      } else {
        // 인증코드 요청
        http.post('/message/auth_code', {
          user_name: this.info.name.value,
          user_phone: this.info.phone.value
        }).then(() => {
          alert('인증코드를 요청했습니다\n전송된 인증코드를 입력하세요')
          this.info.phone.cert = true
          this.focusRefs('phoneCertInput')
        }).catch(()=>{
          alert('인증코드 요청에 실패했습니다.\n다시 시도해주세요');
        }).finally(()=>{
          this.loading = false;
        })
      }
    },
    checkPhoneCert () {
      if(this.loading) {
        return
      }

      this.loading = true;
      if (this.isEmpty(this.phoneCert)) {
        alert('인증코드를 입력하세요')
        this.focusRefs('phoneCertInput')
        return this.loading = false;
      } else {
        // 인증코드 확인
        http.post('/message/auth_code_confirm', {
          user_phone: this.info.phone.value,
          auth_code: this.phoneCert
        }).then(res => {
          alert('인증이 완료되었습니다')
          this.info.phone.pass = true
        }).catch(err=>{
          if(err.response.data.message === 'not match') {
            alert('인증번호가 일치하지 않습니다.')
          } else {
            alert('인증코드 유효시간이 만료되었습니다.\n인증코드를 다시 요청해주세요.')
            this.info.phone.cert = false
          }
        }).finally(()=>{
          this.loading = false;
        })
      }
    },
    checkDuplicateEmail () {
      if(this.loading) {
        return
      }

      this.loading = true;
      if (this.isEmpty(this.info.email.value)) {
        alert('이메일을 입력하세요')
        this.focusRefs('emailInput')
        return this.loading = false;
      } else if (!regExp.expEmail.test(this.info.email.value)) {
        alert('올바른 이메일 형식이 아닙니다')
        return this.loading = false;
      } else {
        // 이메일 중복검사
        http.get('/user/checkEmail', {
          params: {
            email: this.info.email.value
          }
        }).then(res => {
          if (res.data.success === true) {
            alert('사용 가능한 이메일입니다')
            this.info.email.pass = true
          } else {
            alert('이미 사용중인 이메일입니다')
            this.focusRefs('emailInput')
          }
        }).catch(err => {
          this.info.email.pass = true
          //console.log('checkDuplicateEmail ERROR !!!!!!!!!!!!!!', err)
        }).finally(()=>{
          this.loading = false;
        })
      }
    },
    matchPassword () {
      const pw1 = this.info.pw1.value
      const pw2 = this.info.pw2.value
      this.info.pw1.pass = !this.isEmpty(pw1) && regExp.expPassword.test(pw1)
      if (!this.isEmpty(pw1) && this.info.pw1.pass) {
        this.info.pw2.pass = pw1 === pw2
      }
    },
    phoneFormatter (e) {
      const phoneNumber = e.target.value
      this.info.phone.value = regExp.phoneFormatter(phoneNumber)
    },
    findZipcode () {

      new window.daum.Postcode({
        oncomplete: (data) => {
          this.info.zipcode.value = data.zonecode;
        }
      }).embed(this.$refs.embed);

    },
    nextStep () {
      if(this.loading) {
        return
      }
      if (this.validate.result) {
        if (this.isChild && !this.chkChildAuth) {
          alert('보호자 인증을 진행해주세요.')
          return
        }
        if (this.info.employee.value === 'Y' && this.isEmpty(this.info.employeeNo.value)) {
          alert('임직원번호를 입력하세요.');
          return
        }
        if (this.info.sms.value === 'N' && this.info.nightAdSms.value === 'Y' ||
            this.info.sms.value === 'N' && this.info.nightAdSms.value === 'y'
        ) {
          this.info.nightAdSms.value = 'N'
        } // 야간 광고성 수신 동의 후 광고성 수신 동의를 미동의 체크시 모두 다 미동의 체크로 바꿔줌

        if (this.info.sms.value === 'y' && this.info.nightAdSms.value === 'y') {
          this.info.sms.value = 'N'
          this.info.nightAdSms.value = 'N'
        }


        if (this.info.sms.value === 'Y' && this.info.nightAdSms.value === 'y') {
          this.info.nightAdSms.value = 'N'
        }

        let gender = 'F';
        if (this.info.sex.value === '남') {
          gender = 'M';
        }
        const age = this.certInfo.birthdate.substr(0,4);
        const birthday = `${this.certInfo.birthdate.substr(0,4)}-${this.certInfo.birthdate.substr(4,2)}-${this.certInfo.birthdate.substr(6,2)}`;

        this.loading = true;
        let data = {
          user_id: this.info.id.value,
          user_pw: this.info.pw1.value,
          re_password: this.info.pw2.value,
          user_name: this.info.name.value,
          age: age,
          birthday: birthday,
          mobile: this.info.phone.value,
          gender: gender,
          email: this.info.email.value,
          //ad_message: this.info.sms.value,
          zipcode: this.info.zipcode.value,
          favorite_player: this.info.favorite.value,
          ad_receive_sms: this.info.sms.value,
          night_ad_receive_sms: this.info.nightAdSms.value,
          //ad_receive_email: 'Y',
          job: this.info.job.value,
          marriage: this.info.marry.value,
          di: this.userDi,
          ci: this.userCi,
          ncsafe: '',
          isAppMember: this.isAppMember,
          certType: this.certType
        }

        if (this.info.employee.value === 'Y') {
          data.employeeNo = this.info.employeeNo.value;
        }
        if (this.isChild) {
          data.nokInfo = this.nokInfo // 14세 미만 가입자 일 때 필요한 정보 기입
        }

        http.post(`/user/register?EncodeData=${this.originEncodeData}`, data).then(res => {
          const data = res.data;
          delete localStorage.agreeReceiveAdvertising
          if (res.data.success === true) {
            alert('회원가입이 완료되었습니다')
            if(data.migration.greenSchool.exists === true || data.migration.oldMobile.exists === true || data.migration.greenHouse.exists === true || data.migration.oldMobile.exists === true) {
              this.$emit('confirmMigration', res.data.migration)
              this.$emit('nextStep', '5')
            } else {
              this.$router.push('/member/signin')
            }
          } else {
            alert(res.data.message)
            this.$router.push('/')
          }
        }).catch(err => {
          // console.log('nextStep ERROR !!!!!!!!!!!!', err)
          alert(err.response.data.message);
          this.$router.push('/')
        }).finally(()=>{
          this.loading = false;
        })

      }
      else {
        if (this.validate.inValidValue) {
          alert(`${this.validate.inValidValue}\n항목을 입력하지 않으셨습니다.`)
        }
        if (this.validate.inValidPass.length > 0) {
          const invalidPass = this.validate.inValidPass.join('\n')
          alert(invalidPass)
        }
      }
    },
    cert() { // 14세 미만 보호자 인증
      const liveUrl = 'https://api.jbfc.kr'

      let query = `?type=cert`; // 14세 미만 보호자 인증시 타입 'cert'
        const url = `${liveUrl}/user/checkplus_main` + query
        window.onmessage = (e) => {
          if (e.origin === `${liveUrl}`) {
            const data = JSON.parse(e.data.replace(/&#34;/gm, '"'));
            if (data.err !== 'true') {
              if (dayjs(data.birthdate).isBefore(dayjs().subtract(19, 'year'))) {
                this.nokInfo.MB_NAME = data.name
                this.nokInfo.MB_BIRTHDAY = data.birthdate
                this.nokInfo.safe_di = data.dupinfo
                this.nokInfo.ipin_key = data.conninfo
                this.nokInfo.MB_MOBILE = data.mobileno
                this.nokInfo.sEncData = data.originEncodeData
                alert('보호자 인증에 성공하셨습니다.')
                this.chkChildAuth = true
              } else {
                alert('보호자가 정보가 일치하지 않습니다.')
              }
            }
          }

        }
        popup = window.open(url, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
    },
  },
  beforeDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  }
}
</script>
<style lang="scss" scoped>
.formGroup.employee {
  min-height: 50px;
  padding-top: 5px;

  .label {
    top: 25px;
  }
  .checkAgree {
    margin-top: 0;
    justify-content: left;
  }
  .employeeVal {
    margin-top: 15px;
  }
}
</style>
