<template>
  <div class="teamPage playerDetail">
    <div class="subTop hyundai">
      <!-- 서브 상단 -->
      <div class="top">
        <div class="container">
          <!-- 브레드크럼 -->
          <div class="breadCrumb">
            홈 <span class="arr">&#8250;</span>
            <router-link to="/team/proteam/all">TEAM</router-link>
            <span class="arr">&#8250;</span>
            <router-link to="/team/proteam/all">프로팀</router-link>
            <span class="arr">&#8250;</span>
            <router-link :to="`/team/player/${num}`">{{ profile.name }}</router-link>
          </div>
          <!-- //브레드크럼 -->
          <div class="playerBox">
            <div class="num"><img v-for="(num, idx) in imgToNum(Number(profile.uniform_number), 'number_primary')"
                                  :key="idx" :src="num" class="imgNum"/></div>
            <div class="pos">{{ positionName(profile.position) }}</div>
            <div class="name">{{ profile.name_eng }}</div>
            <div v-if="playerImg(num, 'player_detail')" class="topImg"><img
                :src="playerImg(num, 'player_detail')" alt=""></div>
            <div v-else class="topImg">
              <img src="@/assets/img/player/player_nonePic.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- //서브 상단 -->
    </div>
    <div class="container">
      <!-- 프로필 -->
      <div class="playerDetailSection playerProfile">
        <div class="profileItem">
          <div class="label">생년월일</div>
          <div class="value">{{ profile.setBirthday }}</div>
        </div>
        <div class="profileItem">
          <div class="label">국적</div>
          <div class="value">{{profile.country}}</div>
        </div>
        <div class="profileItem">
          <div class="label">출신고</div>
          <div class="value">{{ profile.school === '' ? '-' : profile.school }}</div>
        </div>
        <div class="profileItem">
          <div class="label">신장</div>
          <div class="value">{{ profile.height }}cm</div>
        </div>
        <div class="profileItem">
          <div class="label">체중</div>
          <div class="value">{{ profile.weight }}kg</div>
        </div>
      </div>
      <!-- //프로필 -->
      <!-- 선수소개 -->
      <div class="playerDetailSection playerIntro" v-if="state">
        <div class="detailTitle">선수소개</div>
        <div class="flexBox">
          <div v-if="playerImg(num, 'player_intro')" class="flexInner"><img :src="playerImg(num, 'player_intro')" alt=""></div>
          <div class="flexInner text">
            {{profile.introduce !== null ? profile.introduce : '선수소개가 없습니다.'}}
          </div>
        </div>
      </div>
      <!-- //선수소개 -->
      <div class="playerDetailSection playerRecord">
        <div class="flexBox">
          <!-- 통산기록 -->
          <div class="flexInner">
            <div class="detailTitle">통산기록</div>
            <div class="rankingTable">
              <table v-if="totalRecord.length !== 0">
                <thead>
                <tr>
                  <th>출전 횟수</th>
                  <th>득점</th>
                  <th>도움</th>
                  <th>슈팅</th>
                  <th>파울</th>
                  <th>경고</th>
                  <th>퇴장</th>
                  <th>실점</th>
                  <th>자책</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="totalPoint in totalRecord">
                  <td>{{ totalPoint.work_qty }}</td>
                  <td>{{ totalPoint.goal_qty }}</td>
                  <td>{{ totalPoint.assist_qty }}</td>
                  <td>{{ totalPoint.st_qty }}</td>
                  <td>{{ totalPoint.fo_qty }}</td>
                  <td>{{ totalPoint.warn_qty }}</td>
                  <td>{{ totalPoint.exit_qty }}</td>
                  <td>{{ totalPoint.loss_goal }}</td>
                  <td>{{ totalPoint.self_goal }}</td>
                </tr>
                </tbody>
              </table>
              <div class="noData" v-else>통산기록이 없습니다</div>
            </div>
          </div>
          <!-- //통산기록 -->
          <!-- 올시즌기록 -->
          <div class="flexInner">
            <div class="detailTitle">올 시즌 기록</div>
            <div class="rankingTable">
              <table v-if="thisYearSeasonRecord.length !== 0">
                <thead>
                <tr>
                  <th>출전 횟수</th>
                  <th>득점</th>
                  <th>도움</th>
                  <th>슈팅</th>
                  <th>파울</th>
                  <th>경고</th>
                  <th>퇴장</th>
                  <th>실점</th>
                  <th>자책</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="thisSeasonPoint in thisYearSeasonRecord">
                  <td>{{ thisSeasonPoint.work_qty }}</td>
                  <td>{{ thisSeasonPoint.goal_qty }}</td>
                  <td>{{ thisSeasonPoint.assist_qty }}</td>
                  <td>{{ thisSeasonPoint.st_qty }}</td>
                  <td>{{ thisSeasonPoint.fo_qty }}</td>
                  <td>{{ thisSeasonPoint.warn_qty }}</td>
                  <td>{{ thisSeasonPoint.exit_qty }}</td>
                  <td>{{ thisSeasonPoint.loss_goal }}</td>
                  <td>{{ thisSeasonPoint.self_goal }}</td>
                </tr>
                </tbody>
              </table>
              <div class="noData" v-else>올 시즌 기록이 없습니다</div>
            </div>
          </div>
          <!-- //올시즌기록 -->
        </div>
      </div>
      <!-- 기사 -->
      <div class="playerDetailSection playerNews" v-if="state">
        <div class="detailTitle">선수 관련 기사</div>
        <div class="noData" v-if="news.length === 0">선수 관련 기사가 없습니다</div>
        <div class="newsList" v-else>
          <div class="newsItem" v-for="newsData in news">
            <router-link :to="{name: 'mediaView', params: {id: newsData.seq, media: 'news'}}">
              <div class="img" v-if="newsData.img!== ''"><img :src="newsData.img"></div>
              <div class="img" v-else><img src="../../assets/img/bg_media_404.png"></div>
              <div class="article">
                <div class="category"><span>뉴스</span></div>
                <p class="subject" v-html="stripTag(newsData.Title)"></p>
                <div class="info">
                  <span class="date">{{ newsData.setRegiDate }}</span>
                  <span class="view">{{ comma(Number(newsData.Hit)) }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>

      </div>
      <!-- //기사 -->
      <!-- 영상 -->
      <div class="playerDetailSection playerVideo" v-if="state">
        <div class="detailTitle">선수 관련 영상</div>
        <div class="noData" v-if="videoRecord.length === 0">선수 관련 영상이 없습니다</div>
        <div class="videoList" v-else>
          <div class="videoItem" v-for="row in videoRecord">
            <a href="#" @click="videoView($event, row.videoId)">
              <div class="img"><img :src="row.photo" alt="" class="video_img">
              </div>
              <div class="content">
                <div class="time">{{row.time}}</div>
                <div class="title">{{row.Title}}</div>
              </div>
            </a>
          </div>
        </div>

      </div>
      <!-- //영상 -->
      <!-- 사진 -->
      <div class="playerDetailSection playerPhoto" v-if="state">
        <div class="detailTitle">선수 사진</div>
        <div class="noData" v-if="currentPhoto.length === 0">선수 사진이 없습니다</div>
        <div class="photoBox" v-else>
          <div class="viewer">
            <img :src="currentPhoto" alt="">
          </div>
          <div class="list">
            <vueper-slides
                :slide-ratio="506/384"
                :infinite="false"
                :duration="5000"
                :arrows="false"
                :touchable="false"
                class="no-shadow">
              <vueper-slide
                  v-for="(p, i) in photo"
                  :key="i">
                <template #content>
                  <div
                      v-for="(img, idx) in p"
                      :key="idx" class="img"
                      :class="{current: img === currentPhoto}"
                      @click="viewImg(img)">
                    <img :src="img" alt="">
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </div>
      <!-- //사진 -->
      <!-- 경기별 출전기록 -->
      <div class="playerDetailSection playerGame">
        <div class="detailTitleBox">
          <div class="detailTitle">경기별 출전기록</div>
          <div class="sort">
        <span class="infoItem">
          <select v-model="currentYear" class="select" @change="initMatchRecodes(currentYear)">
            <option value="">년도선택</option>
            <option
                v-for="(y, i) in year"
                :key="i"
                :value="y.id">
              {{ y.name }}
            </option>
          </select>
        </span>
          </div>
        </div>

        <div class="rankingTable recordTable">
          <table>
            <thead>
            <tr>
              <th>일시</th>
              <th>상대팀</th>
              <th>출전<br>여부</th>
              <th>교체인</th>
              <th>교체<br>아웃</th>
              <th>득점</th>
              <th>도움</th>
              <th>슈팅<br>(S/T)</th>
              <th>파울<br>(C/S)</th>
              <th>OS</th>
              <th>PK<br>(S/F)</th>
              <th>경고</th>
              <th>퇴장</th>
              <th>실점</th>
              <th>자책</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(recordPoint, num) in recordsGame" :key="num">
              <td>{{ recordPoint.setGameDate }}</td>
              <td><img
                  :src="recordPoint.teamLogoImg"
                  alt=""> {{ recordPoint.vs_name }}
              </td>
              <td>{{ recordPoint.Work_Des }}</td>
              <td>{{ recordPoint.Change_i_Des !== "" ? recordPoint.Change_i_Des : '-' }}</td>
              <td>{{ recordPoint.Change_o_Des_ !== "" ? recordPoint.Change_o_Des_ : '-' }}</td>
              <td>{{ recordPoint.Goal_Qty }}</td>
              <td>{{ recordPoint.Assist_Qty }}</td>
              <td>{{ recordPoint.St_Valid_Qty }}/{{ recordPoint.St_Qty }}</td>
              <td>{{ recordPoint.Fo_Qty }}/{{ recordPoint.Fs_Qty }}</td>
              <td>{{ recordPoint.Os_Qty }}</td>
              <td>{{ recordPoint.Pk_Y_Qty }}/{{ recordPoint.Pk_N_Qty }}</td>
              <td>{{ recordPoint.Warn_Qty }}</td>
              <td>{{ recordPoint.Exit_Qty }}</td>
              <td>{{ recordPoint.loss_Point }}</td>
              <td>{{ recordPoint.Self_Goal_Qty }}</td>
            </tr>
            </tbody>
          </table>
          <div class="noData" v-if="recordsGame.length === 0">경기별 출전기록이 없습니다</div>
        </div>
      </div>
      <!-- //경기별 출전기록 -->
      <!-- 시즌별 출전기록 -->
      <div class="playerDetailSection playerSeason">
        <div class="detailTitle">시즌별 출전기록</div>
        <div class="rankingTable recordTable">
          <table>
            <thead>
            <tr>
              <th>시즌</th>
              <th>소속팀</th>
              <th>대회명</th>
              <th>출전<br>횟수</th>
              <th>득점</th>
              <th>도움</th>
              <th>슈팅<br>(S/T)</th>
              <th>파울<br>(C/S)</th>
              <th>경고</th>
              <th>퇴장</th>
              <th>실점</th>
              <th>자책</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="seasonPoint in seasonRecord">
              <td>{{ seasonPoint.meet_year }}</td>
              <td><img :src="seasonPoint.teamLogoImg" alt=""> 전북
              </td>
              <td>{{ seasonPoint.meet_name }}</td>
              <td>{{ seasonPoint.work_qty }}</td>
              <td>{{ seasonPoint.goal_qty }}</td>
              <td>{{ seasonPoint.assist_qty }}</td>
              <td>{{ seasonPoint.st_qty }}</td>
              <td>{{ seasonPoint.fo_qty }}</td>
              <td>{{ seasonPoint.warn_qty }}</td>
              <td>{{ seasonPoint.exit_qty }}</td>
              <td>{{ seasonPoint.loss_goal }}</td>
              <td>{{ seasonPoint.self_goal }}</td>
            </tr>
            </tbody>
          </table>
          <div class="noData" v-if="seasonRecord.length === 0">훈련별 출전기록이 없습니다</div>
        </div>
      </div>
      <!-- //시즌별 출전기록 -->
    </div>
  </div>
</template>

<script>
import http from "@/api/index"
import regExp from '@/common/regExp'
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { stripTag } from '@/library/stripHtml'
import dayjs from "dayjs"
import imgPath from "@/library/imgPath"

export default {
  name: 'proTeamDetail',
  props: [
    'num'
  ],
  components: {
    VueperSlides,
    VueperSlide,
  },
  computed: {
    stripTag() {
      return stripTag
    },
  },
  created() {
    const numArr = ['20190167', '20160047', '20230071', '20170122']
    if (numArr.indexOf(this.num) > -1 ) {
      alert('선수 정보를 불러올 수 없습니다.')
      return this.$router.go(-1)
    } else {
      this.playerId = this.num
    }
    // this.playerId = this.num
    const thisYear = this.$store.state.thisYear
    this.thisYear = thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.init()
    this.photoInit()
  },
  data() {
    return {
      state: false,
      playerId: '',
      player: null,
      currentYear: '',
      yearStart: 2017, // 필터 시작 년도
      year: [],
      thisYear: '',
      pos: {
        'GK': 'GOALKEEPER',
        'DF': 'DEFENDER',
        'MF': 'MIDFIELDER',
        'FW': 'FORWARD'
      },
      photo: [],
      profile: [],
      totalRecord: [], // 통산기록
      recordsGame: [], // 경기별 출전기록
      seasonRecord: [], // 시즌별 출전기록
      lastSeasonYear: '',
      thisYearSeasonRecord: [], // 올시즌기록
      videoRecord: [],
      news: [],
      noData: true,
      currentPhoto: '',
    }
  },
  methods: {
    async init() {
      const player = await http.get(`/player/${this.playerId}`)
      if (player) {
        this.profile = player.data.data.profile.info
        this.profile.setBirthday = dayjs(this.profile.birthday).format('YYYY. MM. DD')
        // 통산기록
        this.totalRecord = player.data.data.total_record
        // 선수 관련 기사
        // this.news = player.data.data.news.list
        // this.news.map(row => {
        //   row.setRegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD')
        //   row.img = imgPath.setPlayerNews(row.TopImage)
        // })
        //
        // 선수 관련 영상
        // const data = player.data.data.videos.list
        // if (data.length !== 0) {
        //   data.map(row => {
        //     row.videoId = row.PhotoFileNM.replace('.jpg', '')
        //     row.photo = imgPath.setPlayerVideo(row.PhotoFileNM)
        //     row.time = row.RegiTime.slice(0, 5)
        //   })
        //   this.videoRecord = data
        // }
        // 올 시즌 기록
        const lastSeasonYear = player.data.data.match.season
        if(lastSeasonYear.length === 0) {
          this.lastSeasonYear = this.thisYear
        }
        else {
          this.lastSeasonYear = Math.max(...lastSeasonYear) // 가장 최근 시즌 년도
        }
        this.thisYearSeasonRecord = player.data.data.season
        this.thisYearSeasonRecord = this.thisYearSeasonRecord.filter(x => x.meet_year === this.lastSeasonYear)
        await this.initMatchRecodes()

        // 시즌별 출전기록
        player.data.data.season.map(el => {
          el.teamLogoImg = imgPath.setTeamLogo('K05')
        })
        this.seasonRecord = player.data.data.season
        this.seasonRecord.sort((a, b) => {return b.meet_year - a.meet_year}) // 최근 시즌년도가 최상으로
        this.photoInit()
      }
    },
    async initMatchRecodes(selectYear) { // 경기별 출전기록
      const params = {
        params: {
          year: this.lastSeasonYear,
        }
      }
      if (selectYear === this.currentYear) {
        params.params.year = this.currentYear
      }
      const recordsGame = await http.get(`/player/${this.playerId}/match`, params)
      this.recordsGame = recordsGame.data.data.record
      this.recordsGame.map(row => {
        row.setGameDate = dayjs(row.Game_Date).format('YYYY.MM.DD')
        row.Change_o_Des_ = row.Change_o_Des.substring(0, row.Change_o_Des.indexOf("(", 0))
        row.teamLogoImg = imgPath.setTeamLogo(row.vs)
      })
    },
    photoInit() {
      // http.get(`/photo/playerdetail?player=${this.playerId}`)
      //     .then(res => {
      //       const data = res.data.data
      //       const photoes = data.map(el => imgPath.setGamePhoto(el))
      //       if (photoes.length !== 0) {
      //         const num = 8 // 슬라이드 하나에 들어가는 포토 개수
      //         let arr = new Array(Math.ceil(photoes.length / num))
      //         for (let i = 0; i < arr.length; i++) {
      //           arr[i] = new Array()
      //           for (let j = 0; j < num; j++) {
      //             arr[i][j] = ''
      //           }
      //         }
      //         for (let i = 0; i < photoes.length; i++) {
      //           arr[parseInt(i / num)][i % num] = photoes[i]
      //         }
      //         // 8개씩 묶어 리스트 처리
      //         this.photo = arr.map(x => {
      //           return x.filter(p => p !== '')
      //         })
      //         this.currentPhoto = arr[0][0]
      //       }
      //     })
    },
    positionName(pos) { // GK -> GOALKEEPER
      pos = pos?.toUpperCase()
      return this.pos[pos]
    },
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    imgToNum(num, path) { // 등번호 등 숫자를 하나씩 쪼개 이미지로 리턴
      const nums = `${this.comma(num)}`.split('')

      function getImg(n) {
        return require(`@/assets/img/${path || 'number'}/${n === ',' ? 'comma' : n}.png`)
      }

      return nums.map(x => getImg(x))
    },
    playerImg(playerId) {
      // try {
      //   // return require(`@/assets/img/player/${path}_${num}.png`)
      //   return imgPath.setPreteamDetail(playerId)
      // } catch (e) {
      //   return null
      // }
      const image = new Image()
      image.src = imgPath.setProteamDetail(playerId)
      image.onerror = () => {
        return null
      }
      return image.src
    },
    videoView(e, videoId) {
      e.preventDefault()
      this.$store.commit('isVideo', videoId)
    },
    viewImg (img) {
      this.currentPhoto = img
    }
  }
}
</script>
<style lang="scss" scoped>
.detailTitleBox {
  display: flex;
  justify-content: space-between;
}
</style>
