export default {
    data : [
        {
            "kl_team_code": "K09",
            "tl_team_code": "65",
            "team_name": "서울",
            "fteam_name": "FC서울"
        },
        {
            "kl_team_code": "K01",
            "tl_team_code": "66",
            "team_name": "울산",
            "fteam_name": "울산현대"
        },
        {
            "kl_team_code": "K02",
            "tl_team_code": "72",
            "team_name": "수원",
            "fteam_name": "수원삼성"
        },
        {
            "kl_team_code": "K05",
            "tl_team_code": "73",
            "team_name": "전북",
            "fteam_name": "전북현대"
        },
        {
            "kl_team_code": "K03",
            "tl_team_code": "74",
            "team_name": "포항",
            "fteam_name": "포항스틸러스"
        },
        {
            "kl_team_code": "K04",
            "tl_team_code": "76",
            "team_name": "제주",
            "fteam_name": "제주유나이티드"
        },
        {
            "kl_team_code": "K18",
            "tl_team_code": "77",
            "team_name": "인천",
            "fteam_name": "인천유나이티드"
        },
        {
            "kl_team_code": "K22",
            "tl_team_code": "79",
            "team_name": "광주",
            "fteam_name": "광주FC"
        },
        {
            "kl_team_code": "K29",
            "tl_team_code": "81",
            "team_name": "수원",
            "fteam_name": "수원FC"
        },
        {
            "kl_team_code": "K10",
            "tl_team_code": "83",
            "team_name": "대전",
            "fteam_name": "대전하나시티즌"
        },
        {
            "kl_team_code": "K17",
            "tl_team_code": "84",
            "team_name": "대구",
            "fteam_name": "대구FC"
        },
        {
            "kl_team_code": "K21",
            "tl_team_code": "87",
            "team_name": "강원",
            "fteam_name": "강원FC"
        },
        {
            "kl_team_code": "K08",
            "tl_team_code": "75",
            "team_name": "성남",
            "fteam_name": "성남FC"
        },
        {
            "kl_team_code": "K07",
            "tl_team_code": "78",
            "team_name": "전남",
            "fteam_name": "전남드래곤즈"
        },
        {
            "kl_team_code": "K35",
            "tl_team_code": "80",
            "team_name": "김천",
            "fteam_name": "김천상무"
        },
        {
            "kl_team_code": "K06",
            "tl_team_code": "82",
            "team_name": "부산",
            "fteam_name": "부산아이파크"
        },
        {
            "kl_team_code": "K31",
            "tl_team_code": "85",
            "team_name": "서울",
            "fteam_name": "서울이랜드FC"
        },
        {
            "kl_team_code": "K27",
            "tl_team_code": "86",
            "team_name": "안양",
            "fteam_name": "FC안양"
        },
        {
            "kl_team_code": "K20",
            "tl_team_code": "88",
            "team_name": "경남",
            "fteam_name": "경남FC"
        },
        {
            "kl_team_code": "K32",
            "tl_team_code": "89",
            "team_name": "안산",
            "fteam_name": "안산그리너스"
        },
        {
            "kl_team_code": "K34",
            "tl_team_code": "90",
            "team_name": "충남아산",
            "fteam_name": "충남아산FC"
        },
        {
            "kl_team_code": "K26",
            "tl_team_code": "100",
            "team_name": "부천",
            "fteam_name": "부천FC"
        },
        {
            "kl_team_code": "K36",
            "tl_team_code": "493",
            "team_name": "김포",
            "fteam_name": "김포FC"
        },
        {
            "kl_team_code": "K37",
            "tl_team_code": "496",
            "team_name": "충북청주",
            "fteam_name": "충북청주프로축구단"
        },
        {
            "kl_team_code": "F29",
            "tl_team_code": "518",
            "team_name": "파주시민",
            "fteam_name": "파주시민축구단"
        },
        {
            "kl_team_code": "E06",
            "tl_team_code": "557",
            "team_name": "킷치",
            "fteam_name": "킷치SC"
        },
        {
            "kl_team_code": "E24",
            "tl_team_code": "555",
            "team_name": "라이언",
            "fteam_name": "라이언 시티 세일러스 FC"
        },
        {
            "kl_team_code": "E25",
            "tl_team_code": "556",
            "team_name": "방콕",
            "fteam_name": "방콕 유나이티드 FC"
        }
    ]
}
