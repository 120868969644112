<template>
  <div class="mypagePage">
    <Top :isLogin="isLogin" :myInfo="myInfo" :dashboardData="dashboardData" />
    <div id="scrollAnchor" class="container">
      <router-view :isLogin="isLogin" :reserveData="reserve" class="content"></router-view>
    </div>
  </div>
</template>

<script>
import Top from '@/components/Mypage/Top'
import dayjs from "dayjs";
import { reserveListCheck } from "@/library/ticketlink";
import http from "@/api/mypageApi";
import tlTeamCode from "@/library/tlTeamCode"
import imgPath from "@/library/imgPath"

export default {
  name: 'Mypage',
  components: {
    Top
  },
  computed: {
    isLogin() {
      return this.$store.getters['isLogin']
    },
    user() {
      return this.$store.getters['user']
    },
  },
  data() {
    return {
      myInfo: {},
      id: '',
      dashboardData: {
        point: 0,
        reserveCnt: 0,
        coupon: 0,
        advance_ticket: 0
      },
      reserve: {
        cnt: 0,
        total: 0,
        list: []
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let myInfoRes = await http.get('/short');
      // dashboard - 예매경기
      let year = dayjs().format('YYYY');
      let startDate = year + '-01-01',
          endDate = dayjs().format('YYYY-MM-DD');
      let seasonDate = year + '1230';

      // this.id = this.user.encrypted_id;
      this.id = localStorage.getItem('encrypted_id')

      let args = {
        encryptedId: this.id,
        startDate,
        endDate
      };

      let reserveListRes = await reserveListCheck(args);
      // console.log(tlTeamCode.data)

      this.myInfo = myInfoRes.data.data;
      if (this.myInfo.season && this.myInfo.season.length < 1) {
        this.myInfo.seasonPass = false;
      }

      this.dashboardData = myInfoRes.data.data.user;
      this.dashboardData.reserveCnt = 0;
      if (!reserveListRes.success) {
        return;
      }
      let newList = reserveListRes.data.reverse();
      let temp = [];
      newList.forEach((el, index) => {
        if (el.playDate > seasonDate) {
          return;
        }
        if (index > 2) {
          this.reserve.total += el.reserveCount;
          temp.push(el);
          return;
        }
        newList.forEach(el => { // 팀코드 추가
          tlTeamCode.data.forEach(row => {
            if(el.awayTeamId === row.tl_team_code) {
              el.away_team_code = row.kl_team_code
            }
          })
        })
        this.reserve.total += el.reserveCount;
        let obj = {
          reserveNo: el.reserveNo,
          vs: el.scheduleName.split('vs')[1],
          gameDate: dayjs(el.playDate + ' ' + el.playTime).format('YYYY.MM.DD(dd) HH:mm'),
          reserveCount: el.reserveCount,
          awayTeamCode : imgPath.setTeamLogo(el.away_team_code)
        }
        this.reserve.list.push(obj);
        temp.push(el);
      })

      let listOfGroups = [...new Set(temp.map(it => it.playDate))];
      this.dashboardData.reserveCnt = listOfGroups.length;
      this.reserve.cnt = listOfGroups.length;
    }
  }
}
</script>
