<template>
  <div class="introSection section5">
    <div class="sectionTitle">역대주장</div>
    <div class="swiperFrame">
      <div class="swiperContent">
        <swiper class="swiper captainSwiper"
          ref="captainSwiper"
          :options="swiperOption">
          <swiper-slide
            v-for="(item, index) in captain"
            :key="index"
            :data-captain="index"
            class="swiperItem">
            <div class="slideInner">
              <div class="frame">
                <div class="img">
                  <img :src="require(`@/assets/img/history/${item.img}.png`)" alt="">
                  <img v-if="item.img2" :src="require(`@/assets/img/history/${item.img2}.png`)" alt="" class="obj">
                </div>
                <div class="careerList">
                  <div class="label">주요경력</div>
                  <ul>
                    <li
                      v-for="(careerItem, index) in item.career"
                      :key="index">
                      {{careerItem}}
                    </li>
                  </ul>
                  <div v-if="item.award" class="award">
                    <div class="label">수상경력</div>
                      <ul>
                        <li
                          v-for="(awardItem, index) in item.award"
                          :key="index">
                          {{awardItem}}
                        </li>
                      </ul>
                  </div>
                </div>
              </div>
              <div class="info">
                <span class="order">{{item.order}}</span>
                <div class="name pretendard">
                  <div class="ko">{{item.name}}</div>
                  <div class="en">{{item.en}}</div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="swiperNav">
      <div class="swiper-button-prev captainSwiperPrev" slot="button-prev"></div>
      <div class="swiper-button-next captainSwiperNext" slot="button-next"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'HistoryCaptain',
  props: [
    'active'
  ],
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    isActive () {
      return this.active && this.active.index === 5
    }
  },
  data () {
    return {
      captain: [
        { order: '1대', name: '이경춘', en: 'LEE GYEONGCHUN', img: 'img_captain_1',
          career: [
            '1994년 전북현대 입단',
            '1994년 히로시마 아시안게임 대표',
            '1994년 대한민국 축구 국가대표팀 발탁',
            '1996년 애틀랜타 올림픽 대표',
            'K리그 통산 185경기 5득점 2도움 기록'
          ]
        },
        { order: '2대', name: '최진철', en: 'CHOI JINCHEOL', img: 'img_captain_2',
          career: [
            '1996년 전북현대 입단',
            '1997년 대한민국 축구 국가대표팀 발탁',
            'K리그 통산 312경기 28득점 11도움 기록',
            '12년간 전북에서 활동한 원클럽맨'
          ],
          award: [
            '자황컵 체육대상 남자 최우수상(2002)',
            '체육훈장 맹호장(2002)',
            '동아시아 축구 선수권 대회 최우수 수비수(2003)',
            'AFC 챔피언스리그 MVP(2006)',
            'K리그 베스트 11(2002, 2003, 2006)',
            'K리그 대상 공로상(2007)',
            '스포츠서울 올해의 프로축구 대상 공로상(2007)'
          ]
        },
        { order: '3대', name: '김현수', en: 'KIM HYEONSU', img: 'img_captain_3',
          career: [
            '2003년 전북현대 입단',
            'K리그 통산 291경기 4득점 9도움'
          ]
        },
        { order: '4대', name: '정경호', en: 'JEONG GYEONGHO', img: 'img_captain_4',
          career: [
            '2007년 전북현대 입단',
            'K리그 통산 238경기 30득점 14도움 기록'
          ]
        },
        { order: '5대', name: '김상식', en: 'KIM SANGSIK', img: 'img_captain_5',
          career: [
            '2009년 전북현대 입단',
            'K리그 통산 458경기 19득점 17도움'
          ],
          award: [
            'K리그1 베스트 11(2009)'
          ]
        },
        { order: '6대', name: '조성환', en: 'CHO SUNGHWAN', img: 'img_captain_6',
          career: [
            '2010년 전북현대 입단',
            'K리그 통산 270경기 8득점 3도움 기록'
          ]
        },
        { order: '7대', name: '이동국', en: 'LEE DONGGOOK', img: 'img_captain_7',
          career: [
            '2009년 전북현대 입단',
            '2010년 동아시아 축구 선수권 대회 국가대표',
            '2010년 FIFA 남아공 월드컵 국가대표',
            'K리그 통산 548경기 228득점 77도움',
            'K리그 통산 득점 1위'
          ],
          award: [
            'K리그1 득점왕(2009)',
            'K리그1 베스트 11(2009, 2011, 2012, 2014, 2015)',
            'K리그1 팬타스틱 플레이어(2009, 2011, 2012, 2014, 2015)',
            'K리그1 MVP(2009, 2011, 2014, 2015)',
            '동아시아 축구 선수권 대회 득점왕(2010)',
            'AFC 챔피언스리그 MVP(2011)',
            'AFC 챔피언스리그 득점왕(2011)',
            'K리그1 도움왕(2011)',
            'K리그 올스타전 MVP(2012)',
            '동아스포츠대상 프로축구 올해의 선수상(2014)',
            'K리그 공로상(2020)',
            'K리그 베스트 포토상(2020)'
          ]
        },
        { order: '8대', name: '권순태', en: 'KWOUN SUNTAE', img: 'img_captain_8',
          career: [
            '2006년 전북현대 입단',
            'K리그 통산 301경기 334실점'
          ],
          award: [
            'K리그1 베스트 11(2014, 2015, 2016)',
            'AFC 챔피언스리그 베스트팀(2016, 2018)'
          ]
        },
        { order: '9대', name: '신형민', en: 'SHIN HYUNGMIN', img: 'img_captain_9',
          career: [
            '2014년 전북현대 입단',
            'K리그 통산 347경기 17득점 8도움 기록(22년 기준)'
          ]
        },
        { order: '10대', name: '이동국', en: 'LEE DONGGOOK', img: 'img_captain_10',
          career: [
            '2009년 전북현대 입단',
            '2010년 동아시아 축구 선수권 대회 국가대표',
            '2010년 FIFA 남아공 월드컵 국가대표',
            'K리그 통산 548경기 228득점 77도움',
            'K리그 통산 득점 1위'
          ],
          award: [
            'K리그1 득점왕(2009)',
            'K리그1 베스트 11(2009, 2011, 2012, 2014, 2015)',
            'K리그1 팬타스틱 플레이어(2009, 2011, 2012, 2014, 2015)',
            'K리그1 MVP(2009, 2011, 2014, 2015)',
            '동아시아 축구 선수권 대회 득점왕(2010)',
            'AFC 챔피언스리그 MVP(2011)',
            'AFC 챔피언스리그 득점왕(2011)',
            'K리그1 도움왕(2011)',
            'K리그 올스타전 MVP(2012)',
            '동아스포츠대상 프로축구 올해의 선수상(2014)',
            'K리그 공로상(2020)',
            'K리그 베스트 포토상(2020)'
          ]
        },
        { order: '11대', name: '홍정호', en: 'HONG JEONGHO', img: 'img_captain_11',
          career: [
            '2018년 전북현대 임대 후 2020년 입단',
            'K리그 통산 189경기 9골 6도움(22년 기준)'
          ],
          award: [
            'K리그1 베스트 11(2019, 2020, 2021)',
            'K리그1 MVP(2021)'
          ]
        },
        { order: '12대', name: '김진수', en: 'KIM JINSU', img: 'img_captain_12',
        },
      ],
      swiperOption: {
        loop: true,
        slidesPerView: 5.4,
        spaceBetween: 0,
        centeredSlides: false,
        navigation: {
          nextEl: '.captainSwiperNext',
          prevEl: '.captainSwiperPrev'
        }
      }
    }
  },
  methods: {
  }
}
</script>
